<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="''">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="卖品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称" />
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<div style="margin: 20px 0;padding-left: 20px;">
				<a-row :gutter="20">
					<a-col class="ui-snack" v-for="item in list" :key="item.id">
						<div style="display: flex;width: 100%;height: 250px;text-align: center;">
							<img style="max-width: 100%;max-height: 250px;margin: auto;" alt="example" :src="item.imgUrl" />
						</div>
						<div style="height: 110px;">
							<div class="ui-title">{{ item.mainTitle }}</div>
							<div style="margin: 6px 0;"><span>库存量：</span>{{ item.stock }}</div>
							<div class="ui-title">
								<span>所属影院：</span>
								<span>{{ item.cinemaName }}</span>
							</div>
						</div>
						<div style="display: flex;margin-top: 20px;justify-content: space-around;">
							<a-button v-permission="['verify_manage_snack_coupon']" type="primary" style="padding-left: 8%;padding-right: 8%;" @click="onShowModal(item)">有券核销</a-button>
							<a-button v-permission="['verify_manage_snack_confirm']" type="primary" style="padding-left: 8%;padding-right: 8%;" ghost @click="onThirdVerify(item)">无券核销</a-button>
						</div>
					</a-col>
				</a-row>
				
				<div v-if="list.length === 0">
					<a-empty />
				</div>
			</div>
			
			<div style="padding-bottom: 10px;padding-right: 20px;text-align: right;">
				<a-pagination v-bind="pagination"></a-pagination>
			</div>
			
			<a-modal v-model:visible="showModal" title="兑换券核销" @cancel="onCancel" @ok="onOk">
				<a-form ref="visForm" name="visForm" :model="modelRef">
					<a-form-item label="卖品兑换券号" name="couponNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.couponNo" placeholder="请输入卖品兑换券号"></a-input>
					</a-form-item>
				</a-form>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getStoreGoodsList } from '@/service/modules/goods.js';
	import { snackThirdVerify } from '@/service/modules/order.js';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				showModal: false,
				visItem: {},
				modelRef: {},
				formState: {
					// organizationId: '',
					cinemaId: ''
				},
				searchData: {},
				organizationList: [],
				cinemaAllList: [],
				searchData: {},
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					saleType: 2,
					isThirdVerify: 1,
					...this.searchData
				}
				this.loading = true;
				try {
					let {
						data
					} = await getStoreGoodsList(postData);
					this.loading = false;
					this.list = data.list;
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = '';
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onShowModal(item) {
				this.visItem = item;
				this.modelRef = {};
				this.showModal = true;
			},
			onCancel() {
				this.$refs.visForm.resetFields();
				this.showModal = false;
				this.modelRef = {};
			},
			onOk() {
				this.$refs.visForm.validateFields().then(() => {
					this.onThirdVerify(this.visItem);
				})
			},
			reset() {
				this.$refs.form.resetFields();
				this.list = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			onThirdVerify(item) {
				this.$confirm({
					title:'提示',
					content: '确定核销该卖品吗?',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await snackThirdVerify({
								goodsId: item.id,
								couponNo: this.modelRef.couponNo || undefined
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('核销成功！');
								this.showModal = false;
								this.visItem = {};
								this.modelRef = {};
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	.ui-snack {
		width: 19%;
		padding: 10px;
		margin-right: 1%;
		margin-bottom: 20px;
		box-shadow: 0px 0px 10px #CCC;
		border-radius: 10px;
		font-weight: 600;
	}
	.ui-snack span {
		font-weight: 500;
	}
	.ui-title {
		margin-top: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		 display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical
	}
	.ui-goods {
		padding: 6px 10px;
		border-radius: 10px;
		border: solid 1px #EEE;
	}

	.ui-goods+.ui-goods {
		margin-top: 4px;
	}
</style>
